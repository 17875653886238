<template>
<!--  <HelloWorld msg="Welcome to Your Vue.js App"/>-->
  <div class="bg_view">
    <img src="./assets/bg.png" class="bg_image" alt="logo"/>
    <div class="title_view">
      <img src="./assets/top_logo.png" class="top_logo" alt="111">
      <view class="title_tabs">
        <a href="https://mp.weixin.qq.com/s/kVAn9zK3EO7KKNi22Tr_Gg" class="title_tabs_text_a"> <h4 class="title_tabs_text">关于锐舞</h4></a>
        <a href="https://mp.weixin.qq.com/s/YdmDNhRQ6rXdhfGOkmUjuw" class="title_tabs_text_a">  <h4 class="title_tabs_text">品质生活</h4></a>
        <a href="https://ranvoo.tmall.com/shop/view_shop.htm?spm=a230r.1.0.0.36b0cfe9ysZbre" class="title_tabs_text_a">  <h4 class="title_tabs_text">官方商城</h4></a>
        <a href="https://mp.weixin.qq.com/s/mtNKEk-VKlHK0bdcC_HSHw" class="title_tabs_text_a">  <h4 class="title_tabs_text">官方服务</h4></a>
      </view>
    </div>
    <view class="bottom_view">
      <h6>©2022 深圳市蓝禾科技有限公司 版权所有</h6>
      <a href="https://beian.miit.gov.cn/" class="bottom_view_a">粤ICP备2021103515号</a>
    </view>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    // HelloWorld
  }
}
</script>

<style>
/*#app {*/
/*  font-family: Avenir, Helvetica, Arial, sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*  text-align: center;*/
/*  color: #2c3e50;*/
/*  margin-top: 60px;*/
/*}*/
.bg_view{
  display: flex;
  flex-direction: column;

  width: 100%;
  /*height: 960px;*/

  /*background-size: (100,100);*/
  /*background-image: url("./assets/bg.png");*/
}

.bg_image{
  width: 100%;
  min-width: 960px;
  /*height: 900px;*/
}

.title_view{
  display: flex;
  flex-direction: row;

  /*height: 100px;*/
  width: 100%;

  position: fixed;

  margin-top: 30px;

  min-width: 960px;

  /*justify-items: center;*/
  /*justify-content: center;*/

  /*background-color: #42b983;*/
}
.top_logo{
  height: 50px;
  /*width: 100px;*/

  margin-left: 40px;
}

.title_tabs{
  display: flex;
  flex-direction: row;

  margin-left: 260px;

  min-width: 600px;

  /*background-color: red;*/
}

.title_tabs_text{
  margin-left: 40px;
  margin-right: 40px;
}

.title_tabs_text_a{
  text-decoration:none;

  color: black;
}


.bottom_view{
  width: 100%;
  height: 100px;

  margin-top: -100px;

  display: flex;
  flex-direction: row;

  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
}

.bottom_view_a{
  text-decoration:none;

  margin-left: 20px;
}
</style>
